import React from "react";
import Marquee from "react-fast-marquee";
import "./optimization.css";
import useScrollWatcher from "../Shared/ScrollWatcher";

const clients = [
  "Empty",
  "bosch-service",
  "oxford-medical",
  // "royal-kebab",
  "shuvar",
  "k-med",
  "car-wash-point",
  "lbs",
  "iqmed",
  "maristella",
  "tehnomed",
  "epilook",
  "chacha",
  "fryway",
  "intima",
  "instytut-zoru",
  "prodental",
  "puzzlean",
  "chesni-pamyatnyky",
  "chernowizza",
  "ded-eats",
  "victor-dim",
  "dea-dent",
  "sedan",
  "1-pravova",
  "sakura",
  "shvydkist",
  "ozzi",
  "mars",
];

const ClientsMarquee = () => {
  const [marqueeRef, isMarqueeActive] = useScrollWatcher();
  return (
    <div ref={marqueeRef}>
      <Marquee
        play={isMarqueeActive}
        speed={75}
        className={"marquee-container"}
        pauseOnClick={false}
        pauseOnHover={false}
        gradient={true}
        gradientColor={"white"}
        gradientWidth={"10vw"}
      >
        {clients.map((logo, index) => (
          <img
            key={index}
            src={`./img/optimization/clients/${logo}.png`}
            alt={`Client ${index + 1}`}
            className={"marquee-item"}
            style={{ maxHeight: "100px", margin: "0 20px", maxWidth: "200px" }}
          />
        ))}
      </Marquee>
    </div>
  );
};

export default ClientsMarquee;
