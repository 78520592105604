import React from "react";
import { useNavigate } from "react-router-dom";

const scrollToView = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
const MenuLink = ({
  text,
  targetId,
  className = "menu__link",
  disableRouting = false,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (window.location.pathname !== "/" && !disableRouting) {
      navigate("/");
      setTimeout(() => {
        scrollToView(targetId);
      }, 500);
    } else {
      scrollToView(targetId);
    }
  };

  return (
    <button className={className} onClick={handleClick}>
      {text}
    </button>
  );
};

export default MenuLink;
