function PublicOffer() {
  return (
    <>
      <style>
        {`
              h1,
              h2 {
              text-align: center;
              font-size: 2em;
              margin: 20px 0;
              font-weight: bold;
          }

              h2 {
              font-size: 1.25em;
          }

              p {
              font-size: 1em;
              line-height: 1.5;
              margin: 10px 0;
          }

              .num-list {
              font-weight: bold;
              padding-left: 10px;
          `}
      </style>
      <div style={{ padding: "10px", backgroundColor: "white" }}>
        <h1>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</h1>
        <h2 style={{ fontSize: "20px" }}>про надання послуг</h2>
        <p>
          Цей документ (надалі – «Оферта») є офіційною, публічною пропозицією
          укласти Договір публічної оферти про надання послуг (далі – «Договір»)
          на вказаних нижче умовах. Згідно із положеннями ст.ст. 633, 641
          Цивільного кодексу України умови Договору є однаковими для всіх
          Замовників, незалежно від статусу (фізична особа, юридична особа,
          фізична особа- підприємець), беззастережне прийняття умов якого
          вважається акцептом даної оферти Замовником та Виконавцем.
        </p>
        <p>
          Фізична особа-підприємець Хацко Марія Степанівна, код РНОКПП
          3700200685, що надалі іменується «Виконавець», що діє на підставі
          Виписки з Єдиного державного реєстру №2004150000000126172 від
          14.08.2023, з однієї сторони,
        </p>
        <p>
          та особа, яка приєдналась до цього Договору, шляхом прийняття
          пропозиції укласти цей Договір на умовах, визначених Виконавцем, що
          іменується надалі “Замовник”, з іншої сторони,
        </p>
        <p>
          надалі разом – “Сторони”, а кожен окремо – “Сторона”, виражаючи свою
          вільну волю та керуючись нормами чинного законодавства України, уклали
          цей Договір на нижчевикладених умовах:
        </p>
        <p className="num-list">1. Терміни та визначення</p>
        <p>
          1.1. Публічна оферта – пропозиція Виконавця, адресована будь-якій
          фізичній та/або юридичній особі, у відповідності зі статтею 633
          Цивільного кодексу України, укласти публічний договір про надання
          маркетингових послуг, таких, як доступ до сервісу, роботи з візуалом,
          контентом, консультаційні послуги та ін. на умовах, що викладені у
          даному Договорі.
        </p>
        <p>
          1.2. Акцепт – повна й безумовна згода Замовника на укладення даного
          Договору на умовах, визначених даним Договором.
        </p>
        <p>
          1.3. Виконавець – Фізична особа-підприємець Хацко Марія Степанівна,
          надає послуги визначені даним Договором, інформація розміщена на
          веб-сторінці Виконавця за посиланням:{" "}
          <a href="https://scout.com.ua">https://scout.com.ua</a>
        </p>
        <p>
          1.4. Замовник – фізична та/або юридична особа, користувач сервісу,
          який здійснив оплату на розрахунковий рахунок виконавця.
        </p>
        <p>
          1.5. Персональні дані – відомості чи сукупність відомостей про фізичну
          особу, яка ідентифікована або може бути конкретно ідентифікована.
        </p>
        <p>
          1.6. Суб’єкт персональних даних – фізична особа, стосовно якої
          відповідно до чинного законодавства здійснюється обробка її
          персональних даних.
        </p>
        <p>
          1.7. Згода суб’єкта персональних даних – будь-яке добровільне
          волевиявлення фізичної особи щодо надання дозволу на обробку її
          персональних даних відповідно до сформульованої мети їх обробки.
        </p>
        <p>
          1.8 Сервіс &ndash; електронний сервіс{" "}
          <a href="https://scout.com.ua">https://scout.com.ua</a>, що дозволяє
          Клієнтам залишати відгуки про компанію замовника.
        </p>
        <p className="num-list">2. Загальні положення</p>
        <p>
          2.1. Договір є публічним договором в розумінні статті 633 Цивільного
          кодексу України, а особа, яка приймає пропозицію (публічну оферту) про
          укладення Договору (здійснює акцепт) стає Замовником відповідно до
          статті 642 Цивільного кодексу України, статті 11 Закону України “Про
          електронну комерцію” та умов цього Договору.
        </p>
        <p>
          2.2. Цей Договір визначає особливості надання та оплати Послуг,
          представлених на сайті{" "}
          <a href="https://scout.com.ua">https://scout.com.ua</a> і/або
          запропонованих Замовнику додатково в процесі переговорів.
        </p>
        <p>
          2.3. Договір є пропозицією (публічною офертою) необмеженому колу
          фізичних і юридичних осіб укласти з Виконавцем Договір шляхом повного
          і безумовного прийняття умов цього Договору.
        </p>
        <p>
          2.4. Умови Договору є однаковими для усіх осіб, які вирішили прийняти
          умови цього Договору.
        </p>
        <p>
          2.5. Договір є договором приєднання в розумінні статті 634 Цивільного
          кодексу України, оскільки його умови визначені Виконавцем і можуть
          бути прийняті Замовником лише шляхом приєднання до запропонованого
          Договору в цілому. У разі незгоди Замовника з усіма чи окремими
          положеннями цього Договору укладення Договору не відбувається.
        </p>
        <p>
          2.6. Приєднанням до Договору вважається оплата послуг Виконавця шляхом
          безготівкового переказу коштів на підставі рахунку, виставленого
          Виконавцем, в тому числі, з використанням електронного платіжного
          засобу.
        </p>
        <p>
          2.7. Договір вважається укладеним з дати надходження Виконавцю оплати
          за послуги, здійсненої Замовником на умовах, встановлених цим
          Договором.
        </p>
        <p>
          2.8. Сторони можуть погодити інший спосіб приєднання до Договору та
          визначити інший момент укладення Договору.
        </p>
        <p>
          2.9. Вчинення Замовником дій, передбачених пунктом 2.6 цього Договору,
          вважається прийняттям Замовником пропозиції про укладення Договору
          (здійснення акцепту), а також підтверджує факт повного і безумовного
          прийняття Замовником умов цього Договору без будь-яких застережень чи
          зауважень відповідно до статті 642 Цивільного кодексу України.
          Приймаючи пропозицію Виконавця (публічну оферту), Замовник
          погоджуєтесь з усіма умовами цього Договору і підтверджує, що йому
          зрозумілий зміст Договору і правові наслідки його укладення.
        </p>
        <p>
          2.10. Договір є електронним договором в розумінні статті 3 Закону
          України &ldquo;Про електронну комерцію&rdquo;, укладається з
          використанням доступних інформаційно-телекомунікаційних систем та не
          може бути визнаний недійсним у зв&rsquo;язку з його вчиненням в
          електронній формі.
        </p>
        <p>
          2.11. Цей Договір у паперовій формі може бути наданий за зверненням
          Замовника, а також може бути роздрукований Замовником самостійно з
          веб-сайту Виконавця:{" "}
          <a href="https://scout.com.ua">https://scout.com.ua</a>.
        </p>
        <p>
          2.12. Предметом даного договору є гарантований збір відгуків від
          клієнтів замовника. А саме: - виконання збору відгуків у кількості
          оплаченій замовником або описаній на сайті{" "}
          <a href="https://scout.com.ua">https://scout.com.ua</a> - до відгуків
          які зараховуються до зібраних по гарантії відносяться: відгуки 5 зірок
          на сервісах Google, Facebook або інших обумовлених з клієнтом. Відгуки
          1,2,3,4 зірки зібрані у сервісі Scout або по телефону з наданням
          запису розмови.
        </p>
        <p className="num-list">3. Предмет договору публічної оферти</p>
        <p>
          3.1. На умовах та в порядку, передбачених цим Договором, Виконавець
          зобов&rsquo;язується надавати, а Замовник приймати та оплачувати
          маркетингові послуги, такі, як доступ до сервісу, робота з візуалом,
          контентом, консультаційні послуги та ін. (надалі &ndash; Послуги), які
          вказані у рахунку-фактурі.
        </p>
        <p>3.2. Послуги надаються на платній основі.</p>
        <p>
          3.3. Всі зміни й доповнення до даного Договору опубліковуються на
          сайті Виконавця.
        </p>
        <p>
          3.4. Всі умови даного Договору є обов&rsquo;язковими як для Замовника,
          так і для Виконавця.
        </p>
        <p>
          Перед початком користування Послугою Замовник зобов&rsquo;язаний
          ознайомитися з умовами даного Договору.
        </p>
        <p className="num-list">4. Порядок укладення договору</p>
        <p>
          4.1. Текст даного Договору є публічною офертою і діє для всіх
          дієздатних фізичних, юридичних осіб, фізичних осіб-підприємців, згідно
          чинного українського законодавства, які мають намір, бажання і
          можливості придбати послуги у Виконавця.
        </p>
        <p>
          4.2. Акцепт оферти &ndash; оплата Замовником наданого Виконавцем
          рахунку-фактури, що визначається цим договором, за узгодженими цінами,
          і прийняття умов оплати, надання Послуг.
        </p>
        <p>
          4.3. Факт оплати Послуг є беззаперечним прийняттям Замовником умов
          даного Договору. З моменту надходження грошових коштів Замовника на
          розрахунковий рахунок Виконавця Замовник розглядається як особа, яка
          вступила з Виконавцем в договірні відносини.
        </p>
        <p>
          4.4. Дія публічного договору оферти не поширюється на відносини і
          зобов&rsquo;язання Замовника і Виконавця в разі, якщо вони оформлені
          окремими індивідуальними договорами в письмовій формі.
        </p>
        <p className="num-list">5. Вартість послуг та порядок розрахунків</p>
        <p>
          5.1. Загальна ціна цього Договору становить суму вартості Послуг,
          наданих Виконавцем протягом строку дії цього Договору.
        </p>
        <p>
          5.2. Оплата вартості послуг здійснюється Замовником шляхом 100%
          передплати на підставі рахунку-фактури, який виставляється Виконавцем,
          не пізніше 10 календарних днів з моменту виставлення рахунку.
        </p>
        <p>
          5.3. Форма оплати &ndash; безготівкова, шляхом переказу коштів на
          розрахунковий рахунок Виконавця зазначений в рахунку-фактурі та
          розділі 12 цього Договору.
        </p>
        <p>
          5.4. Виконавець складає Акти наданих Послуг (далі &ndash; Акти, а
          кожен окремо Акт) за фактом виконання послуг та надсилає їх Замовнику.
        </p>
        <p>
          5.5. Замовник протягом 2 (двох) робочих днів розглядає Акт прийому та
          передачі послуг та приймає його шляхом накладання кваліфікованого
          електронного підпису та печатки Замовника (при використанні печатки у
          господарській діяльності) або надсилає мотивовані зауваження щодо
          Результатів Виконавцю.
        </p>
        <p className="num-list">6. Права і обов&rsquo;язки сторін</p>
        <p>6.1. Виконавець має право:</p>
        <p>
          6.1.1. отримувати від замовника інформацію, необхідну для надання
          послуг;
        </p>
        <p>
          6.1.2. самостійно встановлювати ціну послуг та інші умови їх надання
          шляхом розміщення відповідної інформації на веб-сайті Виконавця;
        </p>
        <p>
          6.1.3. вносити зміни до Договору шляхом їх розміщення на веб-сайті
          Виконавця без спеціального повідомлення Замовника;
        </p>
        <p>
          6.1.4. зберігати та обробляти персональні дані, отримані в процесі
          надання послуг, відповідно до вимог чинного законодавства України;
        </p>
        <p>
          6.1.5. призупинити надання послуг при несвоєчасному виконанні
          Замовником своїх грошових зобов&rsquo;язань з оплати послуг;
        </p>
        <p>
          6.1.6. повністю або частково призупинити надання послуг у разі
          неможливості надання послуг у зв&rsquo;язку з технічними або іншими
          об&rsquo;єктивними причинами.
        </p>
        <p>6.2. Виконавець зобов&rsquo;язаний:</p>
        <p>6.2.1. надавати Послуги з моменту акцепту Договору;</p>
        <p>
          6.2.2. якісно та вчасно надавати Послуги відповідно до умов цього
          Договору за замовленням Замовника;
        </p>
        <p>
          6.2.3. надавати роз&rsquo;яснення з питань, що виникають у Замовника в
          зв&rsquo;язку з наданням йому Послуг;
        </p>
        <p>
          6.2.3. у разі виникнення обставин, які перешкоджають належному
          виконанню своїх обов&rsquo;язків за цим Договором, негайно повідомити
          про це Замовника;
        </p>
        <p>
          6.2.4 вживати будь-яких інших необхідних заходів, потрібних для
          належного виконання своїх зобов&rsquo;язань за цим Договором;
        </p>
        <p>6.2.5. підписувати і передавати Замовнику Акти наданих послуг;</p>
        <p>
          6.2.6. усувати недоліки виконання послуг протягом 3 (трьох) робочих
          днів з дати надходження зауважень від Замовника з описом недоліків,
          які необхідно усунути;
        </p>
        <p>
          6.2.7. забезпечити захист персональних даних, отриманих від Замовника.
        </p>
        <p>6.3. Замовник має право:</p>
        <p>
          6.3.1. отримувати Послуги, надані Виконавцем відповідно до умов
          Договору;
        </p>
        <p>
          6.3.2. отримувати повну та достовірну інформацію про обсяг наданих
          послуг, а також роз&rsquo;яснення з питань, що виникають у Замовника в
          зв&rsquo;язку з наданням йому Послуг.
        </p>
        <p>6.4. Замовник зобов&rsquo;язаний:</p>
        <p>
          6.4.1. самостійно ознайомитись з умовами Договору, розміщеного на
          веб-сайті Виконавця;
        </p>
        <p>
          6.4.2. надавати достовірну і повну інформацію, необхідну для укладення
          та виконання Договору;
        </p>
        <p>
          6.4.3. надати Виконавцю на його вимогу інформаційні матеріали, які
          потрібні для належного виконання зобов&rsquo;язання з виконання
          Послуг, передбачених цим Договором;
        </p>
        <p>
          6.4.4. здійснювати оплату за належним чином надані Послуги на умовах
          та в порядку, визначеному цим Договором, за цінами, визначеними
          Виконавцем;
        </p>
        <p>
          6.4.5. негайно повідомляти Виконавця про зміну своїх реквізитів,
          обставини та факти, які є важливими для надання Послуг за Договором;
        </p>
        <p>
          6.4.6. не копіювати та не розповсюджувати без письмової згоди
          Виконавця отримані в процесі виконання Договору консультації,
          матеріали, презентації, роз&rsquo;яснення, іншу інформацію;
        </p>
        <p>
          6.4.7. приймати від Виконавця належно виконані Послуги, що надаються
          ним за цим Договором на основі Акту наданих послуг;
        </p>
        <p>
          6.5. Сторони зобов&rsquo;язані дотримуватись усіх умов цього Договору
          та усіх додатків до нього. Додатки до Договору є невід&rsquo;ємними
          частинами Договору.
        </p>
        <p className="num-list">7. Відповідальність сторін</p>
        <p>
          7.1. За порушення Договору Сторони несуть відповідальність у
          відповідності до положень чинного законодавства України та умов цього
          Договору.
        </p>
        <p>
          7.2. Порушенням Договору є його невиконання або неналежне виконання,
          тобто виконання з порушенням умов, визначених змістом цього Договору.
        </p>
        <p>
          7.3. Виконавець не несе відповідальності за неналежне виконання або
          невиконання умов цього Договору у випадку, якщо Замовник не забезпечив
          надання інформації та документів, необхідних для надання послуг, або у
          разі надання інформації та документів у неповному обсязі чи надання
          суперечливої і/або недостовірної інформації та/або документів.
        </p>
        <p>
          7.4. Замовник несе відповідальність за достовірність і повноту
          відомостей, вказаних при реєстрації. У випадку якщо Замовник не вказав
          або невірно вказав відомості про себе, Виконавець не відповідає за
          збитки Замовника, понесені в результаті відмови в поверненні
          передоплати, наданні послуг, та/або здійснення інших дій внаслідок
          неможливості правильної ідентифікації Замовника.
        </p>
        <p>
          7.5. У випадку здійснення Замовником оплати, але неотримання послуг з
          незалежних від волі Виконавця причин, такі кошти можуть бути
          зараховані за згодою Замовника в рахунок майбутніх платежів за
          послуги, що будуть надані Виконавцем, або підлягають поверненню на
          вимогу Замовника.
        </p>
        <p>
          7.6. Сторони звільняються від відповідальності за невиконання або
          неналежне виконання зобов&rsquo;язань за Договором, якщо це сталося
          внаслідок дії обставин непереборної сили, тобто надзвичайних та
          непередбачуваних обставин, які виникли після укладення цього Договору,
          не залежать від волі Сторін і перебувають поза їх контролем, до яких
          відносяться, зокрема, але не виключно, бойові дії, революції, державні
          перевороти, стихійні лиха, техногенні та інших аварій, аварії в
          системі електропостачання та зв&rsquo;язку, страйки, локаути,
          диверсійні та терористичні акти, рішення державних та місцевих органів
          влади, епідемії, пандемії, надзвичайні ситуації, що роблять
          об&rsquo;єктивно неможливим виконання умов Договору (далі &ndash;
          &ldquo;форс-мажор&rdquo;).
        </p>
        <p>
          7.7. Сторона, для якої настав форс-мажор, зобов&rsquo;язана повідомити
          про це іншу Сторону протягом 3(трьох) календарних днів з дати, коли
          Стороні стало відомо про настання форс-мажору, та надати іншій Стороні
          належні документи для підтвердження форс-мажору.
        </p>
        <p>
          7.8. З моменту отримання повідомлення про форс-мажор іншою Стороною
          виконання умов Договору призупиняється, а строк виконання
          зобов&rsquo;язань за Договором подовжується на період існування
          форс-мажору.
        </p>
        <p>
          7.9. Призупинення виконання умов Договору означає, що Виконавець
          припиняє надання послуг, передбачених Договором, а Замовник не повинен
          здійснювати подальші платежі за послуги, які не були надані, без
          негативних наслідків, штрафних санкцій та відповідальності для кожної
          з Сторін.
        </p>
        <p>
          7.10. Сторона, для якої настав форс-мажор, зобов&rsquo;язана
          повідомити іншу Сторону про припинення дії форс-мажору протягом 3
          (трьох) календарних днів з дати, коли Стороні стало відомо про
          припинення дії форс-мажору.
        </p>
        <p className="num-list">8. Термін дії договору</p>
        <p>
          8.1. Термін дії Договору визначається з моменту акцепту Оферти
          Замовником та діє протягом терміну доступу до сервісу, вказаному у
          рахунку, але у будь якому випадку до повного виконання Сторонами своїх
          зобов&rsquo;язань, передбачених Договором.
        </p>
        <p>
          8.2. Пролонгація Договору на наступний строк відбувається за умови
          оплати вартості Послуг Виконавця відповідно до умов даного Договору.
        </p>
        <p>8.3. Договір може бути припинений:</p>
        <p>8.3.1. у будь-який час за згодою Сторін;</p>
        <p>
          8.3.2. за ініціативою однієї із Сторін в разі порушення іншою Стороною
          умов Договору з письмовим повідомленням іншої Сторони. У такому
          випадку Договір вважається розірваним з моменту отримання Стороною,
          яка порушила умови Договору, відповідного письмового повідомлення від
          іншої Сторони;
        </p>
        <p>
          8.3.3. за ініціативою однієї із Сторін за умови письмового
          повідомлення іншої Сторони за 10 (десять) календарних днів до дати
          розірвання Договору;
        </p>
        <p>8.3.4. з інших підстав, передбачених цією Офертою (Договором).</p>
        <p>
          8.4. У разі дострокового припинення договору Сторони зобов&rsquo;язані
          здійснити повні взаєморозрахунки за вже надані Послуги. Датою
          розірвання цього Договору вважається дата кінцевого розрахунку.
        </p>
        <p className="num-list">9. Захист персональних даних</p>
        <p>
          9.1. Кожна із Сторін дає згоду на збір та обробку своїх персональних.
        </p>
        <p>
          9.2. Сторони беруть на себе зобов&rsquo;язання забезпечити належний
          захист персональних даних від незаконної обробки та незаконного
          доступу третіх осіб, включаючи вжиття необхідних заходів для
          запобігання розголошенню персональних даних посадових осіб,
          працівників, уповноважених представників Сторін, якщо такі персональні
          дані були довірені Стороні або стали відомі Стороні у зв&rsquo;язку з
          зобов&rsquo;язаннями за Договором.
        </p>
        <p>
          9.3. Укладенням Договору Замовник підтверджує, що він повідомлений
          (без додаткового повідомлення) про права суб&rsquo;єкта персональних
          даних, встановлені Законом України &ldquo;Про захист персональних
          даних&rdquo;, мету обробки персональних даних, склад і зміст зібраних
          персональних даних, а також умови доступу до персональних даних третіх
          осіб.
        </p>
        <p className="num-list">10. Угода про електронний документообіг</p>
        <p>
          10.1. Сторони визнають юридичну силу документів, що складені в
          електронній формі (заявок, Актів, повідомлень, рахунків, угод, листів
          і ін.), направляються електронною поштою на вказані в договорі
          реквізити, якщо є можливість достовірно встановити, що документ
          походить від сторони договору.
        </p>
        <p>
          10.2. Сторони погодили використання електронно цифрового підпису (ЕЦП)
          у документообігу в межах виконання цього Договору.
        </p>
        <p>
          10.3. Сторони прийшли до згоди керуватися умовами про електронний
          документообіг і електронну взаємодію, викладеним нижче:
        </p>
        <p>
          10.3.1. рахунки та акти складаються письмово у формі електронного
          документа і не оформляються на паперових носіях;
        </p>
        <p>
          10.3.2. відповідно до угоди про електронний документообіг Сторони
          використовують електронні підписи.
        </p>
        <p className="num-list">11. Інші умови договору</p>
        <p>
          11.1. Оферта набуває чинності з моменту публікації на сайті Виконавця
          і діє до моменту відкликання Оферти Виконавцем. Замовник підтверджує,
          що отримав достатню інформацію про повний спектр послуг передбачених
          Договором, і приймає їх без будь-яких застережень.
        </p>
        <p>
          11.2. Замовник підтверджує, що володіє необхідною дієздатністю, а
          також всіма правами та повноваженнями на укладення й виконання умов
          договору.
        </p>
        <p>
          11.3. Інформація, що надається Замовником є конфіденційною. Інформація
          про Замовника використовується виключно в цілях виконання Виконавцем
          Послуг.
        </p>
        <p>
          11.4. Виконавець має право в односторонньому порядку вносити зміни до
          Договору шляхом оприлюднення змін до Договору на веб-сайті Виконавця.
          Зміни до Договору набирають чинності з дати їх оприлюднення на
          веб-сайті Виконавця, якщо інший термін набрання чинності не визначений
          додатково при їх оприлюдненні.
        </p>
        <p>
          11.5. З питань, що виникають у зв&rsquo;язку з укладенням, виконанням
          і припиненням цього Договору та не врегульовані ним, Сторони
          зобов&rsquo;язуються керуватися нормами чинного законодавства України,
          зокрема положеннями Цивільного кодексу України, Господарського кодексу
          України та Закону України &ldquo;Про електронну комерцію&rdquo;.
        </p>
        <p>
          11.6. Будь-які суперечки, що можуть виникнути на підставі даного
          Договору, сторони вирішують шляхом переговорів. У разі недосягнення
          згоди, усі суперечки за даним Договором вирішуються відповідно до
          чинного законодавства України.
        </p>
        <p>
          11.7. Якщо будь-яке положення цього Договору стає недійсним, це не
          впливає на дійсність решти положень Договору. У такому випадку
          Сторони, наскільки це можливо, намагаються домовитися про заміну
          недійсної умови новими дійсними положеннями, що дозволяють максимально
          досягнути початкових намірів Сторін.
        </p>
        <p>
          11.8. Сторони зобов&rsquo;язуються зберігати у таємниці конфіденційну
          інформацію та комерційну таємницю, яка стала їм відомою у
          зв&rsquo;язку з виконанням умов Договору, не розголошувати
          конфіденційну інформацію та комерційну таємницю іншої Сторони, а також
          не використовувати відповідну інформацію в своїх інтересах чи
          інтересах третіх осіб.
        </p>
        <p>
          11.9. Сторони несуть повну відповідальність за повноту та
          достовірність наведених ними в документах реквізитів. Сторони
          зобов&rsquo;язуються негайно повідомляти одна одну про зміну імені,
          найменування, організаційно-правової форми, місцезнаходження (місця
          проживання), адрес для листування, номерів телефонів, адрес
          електронної пошти, реквізитів банківських та інших рахунків. У випадку
          неповідомлення винна Сторона несе ризик настання пов&rsquo;язаних з
          цим несприятливих наслідків (в тому числі, податкових).
        </p>
        <p>
          11.10. Виконавець за цим Договором має статус платника єдиного податку
          3-ї групи і не є платником податку на додану вартість.
        </p>
        <p className="num-list">12. Адреса і реквізити Виконавця:</p>
        <p>
          <strong>
            ФОП Хацко Марія <br />
            Степанівна
          </strong>
          <br />
          код РНОКПП 3700200685,
          <br />
          Юридична адреса: <br />
          81085 Львівська область, <br />
          с. Рясне-Руське, вул. Залізнична, буд. 35
          <br />
          <br />
          <strong>Банківські реквізити для переказів по Україні:</strong>
          <br />
          IBAN UA073220010000026004340075622 <br />
          АТ «УНІВЕРСАЛ БАНК»
        </p>
        <p>
          <br />
          <a
            className="download-link promo__btn button"
            href="/public_offer/public_offer.pdf"
            download="ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ.pdf"
          >
            Завантажити договір
          </a>
        </p>
      </div>
    </>
  );
}

export default PublicOffer;
