import { useTranslation } from "react-i18next";
import useScrollWatcher from "./Shared/ScrollWatcher";
import { Link } from "react-router-dom";

function Error404() {
  const [contactsRef, isContactsVisible] = useScrollWatcher();
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: "fixed",
        top: "50vh",
        left: "50vw",
        transform: "translate(-50%, -50%)",
        maxWidth: 600,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        src="img/not-found.jpg"
        style={{ boxShadow: "0 0 15px 10px #FBFDFC" }}
        alt="404"
      />
      <section
        id="contacts"
        data-watch
        data-watch-threshold="0.5"
        ref={contactsRef}
        className={isContactsVisible ? "contacts _watcher-view" : "contacts"}
        style={{ marginBottom: 0 }}
      >
        <div
          className="contacts__form form-block"
          style={{
            margin: "-20px 0",
            opacity: 1,
            padding: "2rem 3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0",
          }}
        >
          <p
            className={"title"}
            style={{ fontSize: "2.5rem", textAlign: "center" }}
          >
            {t("notFound.title")}
          </p>
          <Link
            to="/"
            style={{
              color: "#fa9d11",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {t("notFound.text")}
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Error404;
