import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import useScrollWatcher from "../Shared/ScrollWatcher";
import { useTranslation } from "react-i18next";

function AdvantagesSection({ advantageSlides }) {
  const { t } = useTranslation();
  const [advantagesRef, isAdvantagesVisible] = useScrollWatcher();
  return (
    <section
      data-watch
      data-watch-threshold="0.5"
      ref={advantagesRef}
      className={
        isAdvantagesVisible ? "advantages _watcher-view" : "advantages"
      }
    >
      <div className="advantages__container">
        <div className="advantages__head">
          <h4 className="advantages__subtitle subtitle title-center">
            {t("advantagesHeader.subtitle")}
          </h4>
          <h2 className="advantages__title title title-center">
            {t("advantagesHeader.title")}
          </h2>
        </div>
        <div className="advantages__body">
          <Swiper
            spaceBetween={50}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            className="advantages__slider"
          >
            {advantageSlides.map((slide, index) => (
              <SwiperSlide key={index} className="advantages-item">
                <div className="advantages-item__head">
                  <div className="advantages-item__num">{index + 1}</div>
                  <h3 className="advantages-item__title">{slide.title}</h3>
                </div>
                <div className="advantages-item__text">{slide.text}</div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="advantages__navigation">
            <button type="button" className="swiper-button-prev">
              {/* SVG для попередньої кнопки */}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.24408 9.41074C4.91864 9.73618 4.91864 10.2638 5.24408 10.5893L11.9107 17.2559C12.2362 17.5814 12.7638 17.5814 13.0893 17.2559C13.4147 16.9305 13.4147 16.4028 13.0893 16.0774L7.01184 10L13.0893 3.92259C13.4147 3.59715 13.4147 3.06951 13.0893 2.74408C12.7638 2.41864 12.2362 2.41864 11.9107 2.74408L5.24408 9.41074Z"
                  fill="#C4D0E4"
                ></path>
              </svg>
            </button>
            <button type="button" className="swiper-button-next">
              {/* SVG для наступної кнопки */}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.7559 9.41074C15.0814 9.73618 15.0814 10.2638 14.7559 10.5893L8.08926 17.2559C7.76382 17.5814 7.23618 17.5814 6.91075 17.2559C6.58531 16.9305 6.58531 16.4028 6.91075 16.0774L12.9882 10L6.91074 3.92259C6.58531 3.59715 6.58531 3.06951 6.91074 2.74408C7.23618 2.41864 7.76382 2.41864 8.08926 2.74408L14.7559 9.41074Z"
                  fill="#C4D0E4"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdvantagesSection;
