import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useScrollWatcher from "./ScrollWatcher";

const ContactForm = ({ country, isSingle = false, forBlog = false }) => {
  const [popup, setPopup] = React.useState(false);
  const [contactsRef, isContactsVisible] = useScrollWatcher();
  const { t } = useTranslation();
  const handleSubmit = (values, { resetForm }) => {
    fetch(
      "https://nethunt.com/service/automation/hooks/66fbc7737d6ffe000853837b",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      },
    );
    resetForm();
    setPopup(true);
  };
  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.nameRequired")),
    phone: Yup.string()
      .matches(/^(380\d{9}|34\d{9}|1\d{10})$/, t("validation.invalidPhone"))
      .required(t("validation.phoneRequired")),
    text: Yup.string(),
  });

  const form = (
    <Formik
      initialValues={{ name: "", phone: " ", text: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form
          className="contacts__form form-block"
          style={
            isSingle
              ? {
                  transform: "none",
                  opacity: "100%",
                  transition: "none",
                  marginLeft: 0,
                }
              : {}
          }
          id="callback-form"
          acceptCharset="UTF-8"
        >
          {isSingle ? (
            <>
              <p style={{ fontSize: "2rem" }}>
                Яка вартість просування в Google Maps?
              </p>
              <p>
                Заповніть форму нижче та отримайте орієнтовний прорахунок
                вартості просування просування на Google карті.
              </p>
            </>
          ) : (
            <div className="form-block__text">{t("contactForm.introText")}</div>
          )}

          <div className="form-block__field">
            <label className="form-block__label">
              {t("contactForm.nameLabel")}
            </label>
            <Field
              type="text"
              name="name"
              placeholder={t("contactForm.namePlaceholder")}
              className="form-block__input"
              autoComplete="off"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="error-message"
            />
          </div>

          <div className="form-block__field">
            <label className="form-block__label">
              {t("contactForm.phoneLabel")}
            </label>
            <PhoneInput
              country={country === "en" ? "us" : country}
              onlyCountries={["ua", "es", "us"]}
              placeholder={t("contactForm.phonePlaceholder")}
              value={values.phone}
              onChange={(phone) => setFieldValue("phone", phone)}
              inputClass="form-block__input tel-input"
              countryCodeEditable={false}
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="error-message"
            />
          </div>
          {!isSingle && (
            <div className="form-block__field">
              <label className="form-block__label">
                {t("contactForm.messageLabel")}
              </label>
              <Field
                as="textarea"
                name="text"
                placeholder={t("contactForm.messagePlaceholder")}
                className="form-block__textarea"
              />
              <ErrorMessage
                name="text"
                component="div"
                className="error-message"
              />
            </div>
          )}

          <button
            data-popup="#popup-success"
            type="submit"
            className="form-block__btn button"
            disabled={isSubmitting}
          >
            {t("contactForm.submitButton")}
          </button>
        </Form>
      )}
    </Formik>
  );

  const fullSection = (
    <section
      id="contacts"
      data-watch
      data-watch-threshold="0.5"
      ref={contactsRef}
      className={isContactsVisible ? "contacts _watcher-view" : "contacts"}
    >
      <div className="contacts__container">
        <div className="contacts__body">
          <div className="contacts__content">
            <div className="contacts__head">
              <h4 className="contacts__subtitle subtitle">
                {t("contacts.introduction")}
              </h4>
              <h2 className="contacts__title title">{t("contacts.offer")}</h2>
            </div>

            <div className="contacts__img">
              <img
                src={
                  forBlog ? "/img/contacts/robot.png" : "img/contacts/robot.png"
                }
                alt="Image"
              />

              <img
                className="contacts__decor"
                src={forBlog ? "/img/contacts/box.png" : "img/contacts/box.png"}
                alt="Image"
              />
            </div>
          </div>
          {form}
          <div className={`popup ${popup ? "popup_show" : ""}`}>
            <div className="popup__wrapper">
              <div className="popup__content">
                <button
                  type="button"
                  onClick={() => setPopup(false)}
                  className="popup__close"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26 2L2 26M2 2L26 26"
                      stroke="#15223A"
                      strokeWidth="4"
                    />
                  </svg>
                </button>
                <div className="popup__img">
                  <img src="img/tick.svg" alt="tick" />
                </div>
                <div className="popup__title">
                  {t("contactForm.popupTitle")}
                </div>
                <div className="popup__text">{t("contactForm.popupText")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return isSingle ? form : fullSection;
};

export default ContactForm;
