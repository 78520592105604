import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useScrollToTopOnAnyPathChange() {
  const location = useLocation();
  const scrollToView = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "instant" });
    }
  };
  useEffect(() => {
    scrollToView("header");
  }, [location.pathname]);
}

export default useScrollToTopOnAnyPathChange;
