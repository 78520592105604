import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Landing/Home";
import MainLayout from "./Shared/MainLayout";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Blog from "./Blog/Blog";
import Post from "./Blog/Post";
import Error404 from "./Error404";
import Optimization from "./Optimization/Optimization";
import PublicOffer from "./PublicOffer/PublicOffer";
import useScrollToTopOnPathChange from "./Shared/useScrollToTopOnPathChange";

const AppRoutes = () => {
  useScrollToTopOnPathChange();
  const setEqualHeight = () => {
    let elements = document.querySelectorAll(".item-tariffs__head");
    let maxHeight = 0;

    elements.forEach(function (element) {
      element.style.height = "auto";
      let elementHeight = element.offsetHeight;
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    });

    elements.forEach(function (element) {
      element.style.height = maxHeight + "px";
    });
  };
  const { i18n } = useTranslation();

  const lang = localStorage.getItem("lang");
  useEffect(() => {
    i18n.changeLanguage(lang);
    setEqualHeight();
    window.addEventListener("resize", setEqualHeight);

    return () => {
      window.removeEventListener("resize", setEqualHeight);
    };
  }, []);
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<Post />} />
        <Route path="/google-maps-optimization" element={<Optimization />} />
      </Route>
      <Route path="/public-offer" element={<PublicOffer />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
