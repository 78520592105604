function CalculateDesiredRating(currentRating, currentVotes, desiredRating) {
  const desRating = desiredRating;
  if (
    currentRating < 0 ||
    currentRating > 5 ||
    desiredRating < 0 ||
    desiredRating > 5 ||
    currentVotes < 0 ||
    typeof currentRating === "string" ||
    typeof currentVotes === "string" ||
    typeof desiredRating === "string"
  ) {
    return "invalidValues";
  }

  let additionalVotes;

  if (desiredRating >= 4.91 && desiredRating < 5) {
    desiredRating = 4.9;
    additionalVotes = Math.ceil(
      (desiredRating * currentVotes - currentRating * currentVotes) /
        (5 - desiredRating),
    );
    let multiplier = 1 + 0.025 * Math.ceil((desiredRating - 4.9) * 100);
    additionalVotes = Math.ceil(additionalVotes * multiplier);
  } else if (desiredRating === 5) {
    desiredRating = 4.9;
    additionalVotes = Math.ceil(
      (desiredRating * currentVotes - currentRating * currentVotes) /
        (5 - desiredRating),
    );
    additionalVotes = Math.ceil(additionalVotes * 1.25);
  } else {
    additionalVotes = Math.ceil(
      (desiredRating * currentVotes - currentRating * currentVotes) /
        (5 - desiredRating),
    );
  }

  if (additionalVotes < 0) {
    additionalVotes = 0;
  }
  return [desRating, additionalVotes];
}

export default CalculateDesiredRating;
