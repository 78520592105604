import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./blog.css";
import { useTranslation } from "react-i18next";

function Blog() {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("ua");
    fetch("/posts.json")
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="blog__container">
      {cards.map((post) => (
        <div
          onClick={() => navigate(`/blog/${post.id}`)}
          className="blog__card"
          key={post.id}
        >
          <img src={`/img/blog/${post.image}`} alt="" />
          <div className="blog__card-info">
            <p className="blog__card-title">{post.title}</p>
            <p className="blog__card-date item-tariffs__li--disabled">
              {post.date}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blog;
