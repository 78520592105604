import { toBase64 } from "js-base64";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuLink from "../Shared/MenuLink";

const public_key = "i71497685152";
const private_key = "C6wbKsYXtxVcg1wjMtW0Xmyymkvgfo7FgUhsLkSW";

function uniqid(prefix = "", random = false) {
  const sec = Date.now() * 1000 + Math.random() * 1000;
  const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
  return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ""}`;
}

async function calculateSHA1ToBase64(input) {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest("SHA-1", dataBuffer);
  const byteArray = new Uint8Array(hashBuffer);
  return btoa(String.fromCharCode(...byteArray));
}

const CreatePayment = ({ amount, currency, description }) => {
  const [formData, setFormData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const createPayment = async () => {
      const subscriptionParams = {
        public_key: public_key,
        version: "3",
        action: "pay",
        amount: amount,
        currency: currency,
        description: description,
        order_id: uniqid(),
      };
      const data = toBase64(JSON.stringify(subscriptionParams));
      const signature = await calculateSHA1ToBase64(
        private_key + data + private_key,
      );

      setFormData({ data, signature });
    };

    createPayment();
  }, [amount, currency, description]);

  if (!formData) {
    return <div className="item-tariffs__btn button">...</div>;
  }

  return (
    // <form method="POST" action="https://www.liqpay.ua/api/3/checkout">
    //   <input type="hidden" name="data" value={formData.data} />
    //   <input type="hidden" name="signature" value={formData.signature} />
    //   <input
    //     type="submit"
    //     className="item-tariffs__btn button"
    //     value={t("choose_button")}
    //   />
    // </form>
    <MenuLink
      className="item-tariffs__btn button"
      text={t("choose_button")}
      targetId={"contacts"}
    />
  );
};

export default CreatePayment;
